
import React, { useState } from "react"
import { graphql, navigate } from 'gatsby'
import SEO from "../components/seo"
import TopMenu from "../components/topmenu"
import CookiesText from "../components/cookies-policy-text";
import Footer from "../components/footer";
import {FormGroup, Checkbox, FormControlLabel, Button} from '@material-ui/core';


function CookiesPolicyPageTemplate({data, pageContext}){
    const slug = data.page.slug;
    const page = data.page.frontmatter;

    const rejectGoogleAnalytics = () => {
      window.gaOptout();
    }

    const [googleAccepted, setGoogleAccepted] = useState(true);

    const handleCheckBox = (e) => {
      setGoogleAccepted(e.target.checked);
      console.log(googleAccepted);
    }

    const buttonClicked = () => {
      if (!googleAccepted) {
        //window.gaOptout();
        console.log("Google not accepted");
      }
      navigate(-1);
    }
    
    return(
        <section className="bot-page bg-green-light">
                <SEO
                title="Cookies settings"
                description={page.seodescription}
                keywords={[`Embedded`, `Sulautetut`, `sulautettu`, `Tickingbot`, `software`, `consultant`, `ohjelmistokonsultti`, `Test automation`, `testiautomaatio`, `testausautomaatio`]} 
                />
                <TopMenu 
                language={pageContext.language} 
                languages={pageContext.languages} 
                slug={slug} translation={pageContext.translation}
                jobs={data.index.frontmatter.jobs}
                listJobs={data.jobs.nodes}
                services={data.index.frontmatter.services.list}
                settings={data.settings.childMdx.frontmatter}
                ></TopMenu>
                <div className="bot-page__in">
                  <div className="container-900">
                    <div className="bot-section-text">
                      <CookiesText page={page}></CookiesText>
                      <FormGroup>
                        <FormControlLabel disabled
                          control={<Checkbox checked id="agree-basic" name="agree-basic" onChange={()=>{}}/>}
                          label="Basic cookies"
                        />
                        <FormControlLabel
                          control={<Checkbox color="primary" defaultChecked id="agree-ga" name="agree-ga" onChange={handleCheckBox}/>}
                          label="Google Analytics"
                        />
                      </FormGroup><br/>
                      <Button variant="contained" color="primary" onClick={buttonClicked}>Back</Button>
                      <p className="bot-copyright tac">© {new Date().getFullYear()} by {data.settings.childMdx.frontmatter.companyname}</p>
                    </div>
                  </div>
                </div>
                <Footer settings={data.settings.childMdx.frontmatter}></Footer>

        </section>

    )

}
export const pageQuery = graphql`
  query CookiesById($id: String!, $language: String!) {
    page: mdx( id: { eq: $id }) {
      id
      slug
      frontmatter {
        title
        seodescription
        description
      }
    }
    jobs: allMdx(filter: {frontmatter: {contentType: {eq: "job"}, language: {eq: $language}}}) {
      nodes {
        fields{
          slug
        }
        frontmatter {
          title
          slug
          enabled
        }
      }
    }
    index: mdx(frontmatter: {templateKey: {eq: "templates/index"}, language: {eq: $language}}) {
      frontmatter {
        services {
          list: services {
            title
          }
        }
        jobs {
          list{
            position
          }
        }
      }
    }
    settings: file(sourceInstanceName: {eq: "settings"}, name: {eq: "settings"}) {
      childMdx {
        frontmatter {
          companyname
          sitename
          address
          address2
          phone
          linkedin
          email
        }
      }
    }
  }
`


export default CookiesPolicyPageTemplate